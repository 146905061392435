<template>
    <div class="home">
        <p>hello !!!</p>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    
    export default defineComponent({
        name: 'Home'
    })
    
</script>
